.progressCircleContainer {
  position: relative;
  width: 160px;
  height: 160px;
}

.outerCircle {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  padding: 20px;
  box-shadow: 6px 6px 20px 0px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.innerCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
}

.quePosition {
  font-weight: 600px;
  font-size: 60px;
  color: #445a3e;
}

.progressCircleSVG {
  position: absolute;
  fill: none;
  stroke: url(#test);
  stroke-width: 20px;
  stroke-dasharray: 500;
  /* stroke-dashoffset: 472; */
}

.progressCircleAnimate {
  animation: animate-progress-bar 2s linear infinite;
}

@keyframes animate-progress-bar {
  0% {
    stroke-dashoffset: 472;
  }
  50% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.leaveQueBtn {
  background-color: #445a3e !important;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 200px;
  border: none !important;
}

.button {
  background-color: #445a3e !important;
  border: none !important
}

.container {
  height: 80vh
}